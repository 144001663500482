import { jsPDF } from "jspdf";
import { jwtDecode } from "jwt-decode"; //  <-- Import jwt-decode

// ---------------------------------------------------------------------
//                               ICONS
// ---------------------------------------------------------------------
export const ArrowIcon = ({ className }) => (
  <svg
    className={className}
    fill="currentColor"
    viewBox="0 0 24 24"
    style={{ width: "24px", height: "24px" }}
  >
    <path d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
  </svg>
);

export const HamburgerIcon = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    fill="currentColor"
    style={{ width: "32px", height: "32px" }}
  >
    <path d="M3 6H21V8H3V6ZM3 11H21V13H3V11ZM3 16H21V18H3V16Z" />
  </svg>
);

export const DownloadIcon = ({ className }) => (
  <svg
    className={className}
    fill="currentColor"
    viewBox="0 0 24 24"
    style={{ width: "24px", height: "24px" }}
  >
    <path d="M13 3v5.586l1.707-1.707 1.414 1.414L12 12.414l-4.121-4.121 1.414-1.414L11 8.586V3h2zm-7 16h12v2H6v-2z" />
  </svg>
);

export const DownArrowIcon = ({ className }) => (
  <svg
    className={className}
    fill="currentColor"
    viewBox="0 0 24 24"
    style={{ width: "18px", height: "18px" }}
  >
    <path d="M7.41,8.58L12,13.17l4.59-4.59L18,10l-6,6-6-6L7.41,8.58Z" />
  </svg>
);

// Icons for TTS
export const SpeakerIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      height="1em"
      width="1em"
      viewBox="0 0 576 512"
    >
      <path d="M256 88v336c0 13.3-10.7 24-24 24-5.9 0-11.6-2.2-16-6.1L140.2 
      352H56c-13.3 0-24-10.7-24-24V184c0-13.3 10.7-24 24-24h84.2l75.8-69.9c4.4-3.9 
      10.1-6.1 16-6.1 13.3 0 24 10.7 24 24z" />
    </svg>
  );
}

export const PauseIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      height="1em"
      width="1em"
      viewBox="0 0 320 512"
    >
      <path d="M48 64C21.5 64 0 85.5 0 112v288c0 26.5 21.5 
      48 48 48h16c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm208 
      0c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h16c26.5 0 
      48-21.5 48-48V112c0-26.5-21.5-48-48-48h-16z"/>
    </svg>
  );
}

export const PlayIcon = ({ className }) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      height="1em"
      width="1em"
      viewBox="0 0 384 512"
    >
      <path d="M73 39c-39.5 23-73 64-73 105v224c0 41 33.5 82 73 105 39.6 23 89.3 23 
      128.9 0l160-96c39.6-23 64.1-63 64.1-105s-24.5-82-64.1-105l-160-96c-39.6-23-89.3-23-128.9 
      0z"/>
    </svg>
  );
}

// ---------------------------------------------------------------------
//                       TOKEN EXPIRY HELPERS
// ---------------------------------------------------------------------
export function isTokenExpired(token) {
  if (!token) return true;
  try {
    const decoded = jwtDecode(token);
    // JWT exp is in seconds; Date.now() is in ms => decoded.exp * 1000
    return decoded.exp * 1000 < Date.now();
  } catch (error) {
    return true;
  }
}

// ---------------------------------------------------------------------
//                      PDF DOWNLOAD HELPER
// ---------------------------------------------------------------------
export function handleDownloadPDF(messages) {
  const doc = new jsPDF({
    unit: "pt",
    format: "letter" // 8.5 x 11 inches
  });

  // PAGE LAYOUT CONSTANTS
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // We'll define margins in points (72 pts = 1 inch)
  const marginLeft = 60;
  const marginRight = 60;
  const marginTop = 80;
  const marginBottom = 60;

  // We control vertical writing position with yPos
  let yPos = marginTop;

  // For line spacing
  const lineHeight = 14;

  // This helps us track page numbers
  let currentPage = 1;

  // Generate a string for today's date/time
  const dateStr = new Date().toLocaleString();

  // -------------------------------------------------------------------
  // 1) PAGE HEADER & FOOTER
  // -------------------------------------------------------------------
  function addHeader() {
    // Title
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(14);
    doc.text("NewTherapy.ai - Session Transcript", marginLeft, 40);

    // Date
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(70, 70, 70);
    doc.text(`Date: ${dateStr}`, marginLeft, 55);

    // Reset main text color after the header
    doc.setTextColor(0, 0, 0);

    // Move yPos to marginTop
    yPos = marginTop;
  }

  function addFooter(pageNum) {
    // Simple page numbering (optional)
    doc.setFontSize(10);
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(100, 100, 100);
    const pageStr = `Page ${pageNum}`;
    doc.text(pageStr, pageWidth - marginRight, pageHeight - 20, {
      align: "right"
    });

    // Reset color
    doc.setTextColor(0, 0, 0);
  }

  // We'll define a helper to create a new page:
  function addNewPage() {
    doc.addPage();
    currentPage++;
    addHeader();
    addFooter(currentPage);
  }

  // Start with page 1
  addHeader();
  addFooter(currentPage);

  // -------------------------------------------------------------------
  // 2) RENDER MESSAGES
  // -------------------------------------------------------------------
  doc.setFontSize(11);
  doc.setFont("Helvetica", "normal");

  messages.forEach((msg) => {
    const role = msg.sender === "ai" ? "Therapist" : "Client";
    const messageText = msg.text || "";

    // Distinguish the role in bold
    doc.setFont("Helvetica", "bold");
    doc.setTextColor(40, 40, 40);
    doc.text(`${role}:`, marginLeft, yPos, { baseline: "top" });

    // measure the "role" text to offset the actual message
    const roleWidth = doc.getTextWidth(`${role}:`);
    const textStartX = marginLeft + roleWidth + 10;

    // Return to normal font for the message text
    doc.setFont("Helvetica", "normal");
    doc.setTextColor(60, 60, 60);

    // We'll split text into lines that fit the width
    const maxTextWidth = pageWidth - marginRight - textStartX;
    const lines = doc.splitTextToSize(messageText, maxTextWidth);

    // Check if we have enough space for all lines on this page
    const neededSpace = lines.length * lineHeight + 10; // +10 padding
    if (yPos + neededSpace > pageHeight - marginBottom) {
      // Not enough space, create a new page
      addNewPage();
      // yPos reset by addHeader, so we need to place role again
      doc.setFont("Helvetica", "bold");
      doc.setTextColor(40, 40, 40);
      doc.text(`${role}:`, marginLeft, yPos, { baseline: "top" });

      // measure again for new page
      const newRoleWidth = doc.getTextWidth(`${role}:`);
      doc.setFont("Helvetica", "normal");
      doc.setTextColor(60, 60, 60);
      // textStartX might differ if role has different length, but typically same
      const newTextStartX = marginLeft + newRoleWidth + 10;

      // Now we can safely render lines
      lines.forEach((line, index) => {
        const lineYPos = yPos + index * lineHeight;
        doc.text(line, newTextStartX, lineYPos, { baseline: "top" });
      });
      yPos += lines.length * lineHeight + 10;
    } else {
      // Enough space on current page
      lines.forEach((line, index) => {
        const lineYPos = yPos + index * lineHeight;
        doc.text(line, textStartX, lineYPos, { baseline: "top" });
      });
      yPos += neededSpace;
    }

    // Some spacing after each message
    yPos += 6;
  });

  // -------------------------------------------------------------------
  // 3) SAVE PDF
  // -------------------------------------------------------------------
  doc.save("therapy_transcript.pdf");
}

// ---------------------------------------------------------------------
//                  MICROPHONE / RECORDING HELPERS
// ---------------------------------------------------------------------

/**
 * Starts the recording by:
 * 1) Clearing audio chunks
 * 2) Getting mic stream
 * 3) Creating a MediaRecorder and starting
 * 4) Setting a timeout to auto-stop
 */
export async function startRecording({
  audioChunksRef,
  streamRef,
  mediaRecorderRef,
  setRecording,
  recordingTimeoutRef,
  MAX_RECORDING_TIME,
  handleRecordingStop,
}) {
  audioChunksRef.current = [];
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  streamRef.current = stream;

  mediaRecorderRef.current = new MediaRecorder(stream);
  mediaRecorderRef.current.ondataavailable = (event) => {
    if (event.data.size > 0) {
      audioChunksRef.current.push(event.data);
    }
  };
  mediaRecorderRef.current.onstop = () =>
    handleRecordingStop({
      audioChunksRef,
    });

  mediaRecorderRef.current.start();
  setRecording(true);

  // auto-stop
  recordingTimeoutRef.current = setTimeout(() => {
    stopRecording({
      mediaRecorderRef,
      streamRef,
      setRecording,
      recordingTimeoutRef,
    });
  }, MAX_RECORDING_TIME * 1000);
}

/**
 * Stops the recording if active, stops all mic tracks,
 * and clears the auto-stop timer.
 */
export function stopRecording({
  mediaRecorderRef,
  streamRef,
  setRecording,
  recordingTimeoutRef,
}) {
  if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
    mediaRecorderRef.current.stop();
  }
  if (streamRef.current) {
    streamRef.current.getTracks().forEach((track) => track.stop());
  }
  setRecording(false);
  if (recordingTimeoutRef.current) {
    clearTimeout(recordingTimeoutRef.current);
  }
}

/**
 * Once recording stops, transcribe the audio:
 * 1) Create a Blob
 * 2) Send to server
 * 3) Update the input text
 */
export async function handleRecordingStop({
  audioChunksRef,
  setIsTranscribing,
  setInputText,
}) {
  if (setIsTranscribing) setIsTranscribing(true);
  const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
  const formData = new FormData();
  formData.append("file", audioBlob, "speech.webm");

  try {
    const response = await fetch("/api/transcribe", {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    if (setInputText) {
      setInputText((prev) => (prev ? prev + " " + data.transcription : data.transcription));
    }
  } catch (error) {
    console.error("Error sending audio to server:", error);
    alert("Error transcribing audio. Please try again.");
  } finally {
    if (setIsTranscribing) setIsTranscribing(false);
  }
}

/**
 * If user clicks mic button:
 * 1) If already recording => stop
 * 2) Else => start
 */
export async function handleMicClick({
  recording,
  startRecordingFn,
  stopRecordingFn,
}) {
  if (recording) {
    stopRecordingFn();
  } else {
    await startRecordingFn();
  }
}